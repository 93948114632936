/* eslint-disable */
export const FlipbookLib = (function () {
  'use strict'
  const t = {
    csstransforms3d: !0,
  }
  function e(t, s) {
    if (!(this instanceof e)) {
      if (!t.length) return new e(t, s)
      Array.prototype.forEach.call(t, t => new e(t, s))
    }
    const i = {
      nextButton: document.getElementById(''),
      previousButton: document.getElementById(''),
      canClose: !1,
      arrowKeys: !0,
      initialActivePage: 0,
      onPageTurn: function () {},
      initialCall: !1,
      width: '100%',
      height: '283px',
    }
    ;(this.options = {
      ...i,
      ...s,
    }),
      (this.classNames = {
        page: 'c-flipbook__page',
        hiddenCover: 'hidden-cover',
        atFrontCover: 'at-front-cover',
        atBackCover: 'at-rear-cover',
        firstPage: 'first-page',
        lastPage: 'last-page',
        isReady: 'is-ready',
        isActive: 'is-active',
        isCalling: 'is-calling',
        isAnimating: 'is-animating',
        wasActive: 'was-active',
      }),
      (this.el = document.getElementById(t)),
      (this.el.style.width = this.options.width),
      (this.el.style.height = this.options.height),
      this.el.setAttribute('data-useragent', navigator.userAgent),
      (this.pages = this.el.querySelectorAll(
        `.${this.classNames.page}, .${this.classNames.hiddenCover}`
      )),
      this.options.canClose &&
        (0 === this.options.initialActivePage &&
          this.el.classList.add(this.classNames.atFrontCover),
        this.pages.item(0).classList.add(this.classNames.firstPage),
        this.pages
          .item(this.pages.length - 1)
          .classList.add(this.classNames.lastPage)),
      this.init()
  }
  return (
    'boolean' != typeof t.preserve3d && (t.preserve3d = !0),
    (e.prototype.getActivePages = function () {
      const t = []
      return (
        (this.pages = this.el.querySelectorAll(
          `.${this.classNames.page}, .${this.classNames.hiddenCover}`
        )),
        Array.from(this.pages).forEach((e, s) => {
          e.classList.contains(this.classNames.isActive) && t.push(s)
        }),
        t
      )
    }),
    (e.prototype.init = function () {
      const { el: e, options: s, classNames: i } = this
      e.classList.add(i.isReady)
      const a = s.canClose ? 'even' : 'odd',
        n = s.canClose ? 'odd' : 'even'
      let o = e.querySelectorAll(`.${i.page}:nth-child(${a})`)
      o = o ? Array.from(o) : []
      let r = e.querySelectorAll(`.${i.page}:nth-child(${n})`)
      r = r ? Array.from(r) : []
      let c,
        l = s.initialActivePage
      if ((1 & l && (l -= 1), s.canClose))
        0 === s.initialActivePage &&
          this.pages.item(0).classList.add(i.isActive)
      else {
        const t = document.createElement('div')
        t.classList.add(i.hiddenCover),
          e.prepend(t.cloneNode()),
          e.append(t.cloneNode())
      }
      ;((0 !== s.initialActivePage && s.canClose) || !s.canClose) &&
        Array.from(this.pages).forEach((t, e) => {
          ;(e !== l && e !== l + 1) || t.classList.add(i.isActive)
        }),
        s.initialCall &&
          r[0] &&
          (c = (function (t, e, s) {
            const i = () => {
              t[0].classList.add(e.isCalling),
                setTimeout(() => {
                  t[0].classList.remove(e.isCalling)
                }, 900)
            }
            return setTimeout(i, 500), setInterval(i, 3e3)
          })(r, i)),
        s.previousButton && (o = [...o, s.previousButton]),
        s.nextButton && (r = [...r, s.nextButton]),
        o.forEach(t =>
          t.addEventListener('click', () => this.turnPage('back'))
        ),
        r.forEach(t =>
          t.addEventListener('click', () => {
            this.turnPage('forward'), s.initialCall && clearInterval(c)
          })
        ),
        s.arrowKeys &&
          (function (e, s, i) {
            document.addEventListener('keydown', ({ keyCode: a }) => {
              let n = t.csstransforms3d ? 39 : 37,
                o = t.csstransforms3d ? 37 : 39
              a === o && i('back'),
                a === n && (i('forward'), e.initialCall && clearInterval(s))
            })
          })(s, c, this.turnPage.bind(this))
    }),
    (e.prototype.isLastPage = function () {
      const t = this.getActivePages()[0]
      return this.pages.last().index() === t
    }),
    (e.prototype.isFirstPage = function () {
      const t = this.getActivePages()[0]
      return this.pages.first().index() === t
    }),
    (e.prototype.turnPage = function (e) {
      const { el: s, options: i, classNames: a } = this,
        n = s.querySelectorAll(`.${a.page}.${a.isActive}`)
      let o = s.querySelectorAll(`.${a.page}.${a.isAnimating}`)
      const r = s.querySelectorAll(`.${a.page}, .${a.hiddenCover}`)
      if (!t.preserve3d && o.length > 2) return
      const c = this.getActivePages()[0],
        l = c + 1,
        d = c === (i.canClose ? 0 : 1) && 'back' === e,
        h =
          l === (i.canClose ? this.pages.length - 1 : this.pages.length - 2) &&
          'forward' === e
      if (d || h) return
      let g, v
      if ('number' == typeof e) {
        const t = 1 & e,
          s = (i.canClose ? t : !t) ? e : e + 1,
          a = s - 1
        if (a === c) return
        a > l
          ? ((e = 'forward'), (v = (g = a) + 1))
          : ((e = 'back'), (v = (g = s) - 1))
      } else
        (g = 'forward' === e ? l + 1 : c - 1),
          (v = 'forward' === e ? l + 2 : c - 2)
      'forward' === e && 2 === g && ((g = 1), (v = 2))
      const p = 'back' === e ? n.item(0) : n.item(1)
      let f = r.item(g),
        u = [f]
      ;-1 !== v && (u = [...u, r.item(v)]),
        (o = [f, p]),
        n.forEach(t => {
          t.classList.remove(a.isActive), t.classList.add(a.wasActive)
        }),
        u.forEach(t => {
          t && t.classList.add(a.isActive)
        }),
        t.csstransforms3d &&
          o.forEach(t => {
            t && t.classList.add(a.isAnimating)
          }),
        (function (t, e, s) {
          t.forEach(i => {
            i &&
              [
                'webkitTransitionEnd',
                'oTransitionEnd',
                'msTransitionEnd',
                'transitionend',
              ].forEach(a => {
                const n = s
                i.addEventListener(a, () => {
                  t.forEach(t => {
                    t && t.classList.remove(n.classNames.isAnimating)
                  }),
                    e.forEach(t => {
                      t.classList.remove(n.classNames.wasActive)
                    })
                })
              })
          })
        })(o, n, this),
        i.canClose &&
          (function (t, e, s, i) {
            const a = t[t.length - 1],
              n = t[0] && t[0].classList.contains(e.lastPage),
              o = a && a.classList.contains(e.firstPage)
            'back' === s && o
              ? (i.classList.remove(e.atBackCover),
                i.classList.add(e.atFrontCover))
              : 'forward' === s && n
              ? (i.classList.remove(e.atFrontCover),
                i.classList.add(e.atBackCover))
              : (i.classList.remove(e.atBackCover),
                i.classList.remove(e.atFrontCover))
          })(u, a, e, s),
        i.onPageTurn(s, {
          pagesActive: n,
          children: r,
        })
    }),
    e
  )
})()
