import './flipbook.lib.css'
import { ga } from '@helpers/ga'
import React, { useEffect } from 'react'
import { FlipbookLib } from './flipbook.lib'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'

export const Flipbook = ({
  title,
  imgs,
}: {
  title: string
  imgs: {
    name: string
    publicURL: string
    childImageSharp: IGatsbyImageData
  }[]
}) => {
  useEffect(() => {
    new FlipbookLib('flipbook', {
      height: '600px',
      onPageTurn: (_: any, { children }: any) => {
        const [prev = 0, next = 0] = Array.from(children)
          .map((child: any, i: number) =>
            child.classList.contains('is-active') ? i : null
          )
          .filter(Boolean)

        ga('event', 'click', {
          event_category: 'katalog-ebooks-flipbook',
          event_label: `${title}-[${prev}:${next}]`,
        })()
      },
    })
  }, [])

  return (
    <>
      <div className='self-center'>
        <div className='flex flex-col items-center md:hidden py-2 px-3 rounded bg-yellow-200 text-white mb-3 border border-yellow-700'>
          <svg
            style={{ width: '23px', height: '23px' }}
            className='mr-1'
            viewBox='0 0 24 24'>
            <path
              fill='#b7791f'
              d='M13 14H11V9H13M13 18H11V16H13M1 21H23L12 2L1 21Z'
            />
          </svg>
          <p className='text-yellow-700'>
            Maaf, sila gunakan dekstop untuk paparan yang lebih baik
          </p>
        </div>
      </div>
      <div className='relative' style={{ height: '600px' }}>
        <div className='c-flipbook shadow-2xl' id='flipbook'>
          {imgs.map((img: any, i: number) => (
            <div key={i} className='c-flipbook__page'>
              <GatsbyImage
                image={img.childImageSharp.gatsbyImageData}
                className='rounded cursor-pointer'
                alt={img.name}
                style={{
                  height: '600px',
                  width: '100%',
                }}
              />
            </div>
          ))}
        </div>
      </div>
    </>
  )
}
